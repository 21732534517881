import React from "react";
import process from "../Assets/Main-Background/stitching-main.jpg";
import process2 from "../Assets/Main-Background/process.jpg";
import { TiTickOutline } from "react-icons/ti";

import "../css/Process.css";
function Process() {
  const data = [
    {
      content:
        " 4 Cone Dyeing with 120 Tones dyeing capacity with approved ETP plant.",
    },
    {
      content: " 8 Rapiers and 2 Somet with Bonus Jacquard and 35 Auto Looms ",
    },
    {
      content: " 5 Fabric Cutting machines.",
    },
    {
      content: " 5 Fabric Cutting machines.",
    },
    {
      content:
        " 4 Slitter Professional Pressing equipment with Industrial Steam Boiler System.",
    },
    {
      content:
        " In-house Lab with all testing equipment for fabric and colour fastness.",
    },
    {
      content: " One Metal Detector Machine.",
    },
    {
      content: " One Automatic Fabric checking and rolling machine",
    },
  ];
  return (
    <div className="process">
      <div className="process-top">
        <img src={process} />
      </div>
      <div className="process-head">
        <h3>
          <span>Our</span> Strength
        </h3>
        <p>
          50,000 Sq feet of factory floor Space. In-house Spinning, Dyeing,
          Printing, Weaving, Cutting, Stitching, Finishing and Packing
          facilities. Showroom with extensive product display in Noida, Expo-
          -Mart and Karur. Design studio and Merchandising Team in Noida &
          Karur.
        </p>
      </div>
      <div className="process-main">
        <div className="process-left">
          <img src={process2} />
        </div>
        <div className="process-right">
          <h3>
            Our Strength : <span>Infrastructure</span>
          </h3>
          <div className="process-right-content">
            {data.map((item, index) => (
              <div key={index} className="process-map">
                <TiTickOutline className="icon-process" />
                <p>{item.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Process;
