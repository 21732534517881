import React from "react";
import main from "../Assets/Categories/living/livinglinen_main.jpg";
import curtain1 from "../Assets/Categories/living/curtain1.jpg";
import curtain2 from "../Assets/Categories/living/curtain2.jpg";
import curtain3 from "../Assets/Categories/living/curtain3.jpg";
import curtain4 from "../Assets/Categories/living/curtain4.jpg";
import curtain5 from "../Assets/Categories/living/curtain5.jpg";
import curtain6 from "../Assets/Categories/living/curtain6.jpg";
import curtain7 from "../Assets/Categories/living/curtain7.jpg";
import curtain8 from "../Assets/Categories/living/curtain8.jpg";
import cushion1 from "../Assets/Categories/living/cushion1.jpg";
import cushion2 from "../Assets/Categories/living/cushion2.jpg";
import cushion3 from "../Assets/Categories/living/cushion3.jpg";
import cushion4 from "../Assets/Categories/living/cushion4.jpg";
import cushion5 from "../Assets/Categories/living/cushion5.jpg";
import cushion6 from "../Assets/Categories/living/cushion6.jpg";
import cushion7 from "../Assets/Categories/living/cushion7.jpg";
import cushion8 from "../Assets/Categories/living/cushion8.jpg";
import throws1 from "../Assets/Categories/living/throws1.jpg";
import throws2 from "../Assets/Categories/living/throws2.jpg";
import throws3 from "../Assets/Categories/living/throws3.jpg";
import throws4 from "../Assets/Categories/living/throws4.jpg";
import throws5 from "../Assets/Categories/living/throws5.jpg";
import throws6 from "../Assets/Categories/living/throws6.jpg";
import throws7 from "../Assets/Categories/living/throws7.jpg";
import throws8 from "../Assets/Categories/living/throws8.jpg";

import "../css/ProductSub.css";

function LivingLinen() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={main}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Curtain</span>
        </h3>
        <div className="product-img-flex">
          <img src={curtain1} />
          <img src={curtain2} />
          <img src={curtain3} />
          <img src={curtain4} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={curtain5} />
          <img src={curtain6} />
          <img src={curtain7} />
          <img src={curtain8} />
        </div>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Box</span> Cushion
        </h3>
        <div className="product-img-flex">
          <img src={cushion1} />
          <img src={cushion2} />
          <img src={cushion5} />
          <img src={cushion6} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={cushion7} />
          <img src={cushion8} />
          <img src={cushion3} />
          <img src={cushion4} />
        </div>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Throws</span>
        </h3>
        <div className="product-img-flex">
          <img src={throws1} />
          <img src={throws2} />
          <img src={throws3} />
          <img src={throws4} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={throws5} />
          <img src={throws6} />
          <img src={throws7} />
          <img src={throws8} />
        </div>
      </div>
    </div>
  );
}
export default LivingLinen;
