// import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/Main-Background/logo.jpeg";

import "../css/Nav.css";

const Navbar = () => {
  return (
    <div className="main-div">
      <div className="nav-top-content">
        <div>
          <img className="nav-logo-image" src={logo}></img>
        </div>
        <div className="nav-bottom">
          <h3 className="nav-logo">
            Softline <span>Exports</span>
          </h3>
        </div>
        <input type="checkbox" id="menu-bar"></input>
        <label for="menu-bar">Menu</label>
      </div>
      <div className="navbar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/products">Products</Link>
            <ul>
              <li>
                <Link to="/tablelinen">Table Linen</Link>
              </li>
              <li>
                <Link to="/kitchenlinen">Kitchen Linen</Link>
              </li>
              <li>
                <Link to="/bedlinen">Bed Linen</Link>
              </li>
              <li>
                <Link to="/livinglinen">Living Linen</Link>
              </li>
              <li>
                <Link to="/development">New Developments</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/process">Process</Link>
            <ul>
              <li>
                <Link to="/dyeing">Dyeing</Link>
              </li>
              <li>
                <Link to="/weaving">Weaving</Link>
              </li>
              <li>
                <Link to="/stitching">Stitching</Link>
              </li>
              <li>
                <Link to="/checking">Checking</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
