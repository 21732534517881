import React from "react";
import main from "../Assets/Categories/table/tablelinen-main.jpg";
import table1 from "../Assets/Categories/table/table1.jpg";
import table2 from "../Assets/Categories/table/table2.jpg";
import table3 from "../Assets/Categories/table/table3.jpg";
import table4 from "../Assets/Categories/table/table4.jpg";
import table5 from "../Assets/Categories/table/table5.jpg";
import table6 from "../Assets/Categories/table/table6.jpg";
import table7 from "../Assets/Categories/table/table7.jpg";
import table8 from "../Assets/Categories/table/table8.jpg";
import placemat1 from "../Assets/Categories/table/placemat1.jpg";
import placemat2 from "../Assets/Categories/table/placemat2.jpg";
import placemat3 from "../Assets/Categories/table/placemat3.jpg";
import placemat4 from "../Assets/Categories/table/placemat4.jpg";
import placemat5 from "../Assets/Categories/table/placemat5.jpg";
import placemat6 from "../Assets/Categories/table/placemat6.jpg";
import placemat7 from "../Assets/Categories/table/placemat7.jpg";
import placemat8 from "../Assets/Categories/table/placemat8.jpg";
import runner1 from "../Assets/Categories/table/runner1.jpg";
import runner2 from "../Assets/Categories/table/runner2.jpg";
import runner3 from "../Assets/Categories/table/runner3.jpg";
import runner4 from "../Assets/Categories/table/runner4.jpg";
import runner5 from "../Assets/Categories/table/runner5.jpg";
import runner6 from "../Assets/Categories/table/runner6.jpg";
import runner7 from "../Assets/Categories/table/runner7.jpg";
import runner8 from "../Assets/Categories/table/runner8.jpg";

import "../css/ProductSub.css";

function Tablelinen() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={main}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Table</span> Cloth
        </h3>
        <div className="product-img-flex">
          <img src={table1} />
          <img src={table2} />
          <img src={table3} />
          <img src={table4} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={table5} />
          <img src={table6} />
          <img src={table7} />
          <img src={table8} />
        </div>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Place</span> Mat
        </h3>
        <div className="product-img-flex">
          <img src={placemat1} />
          <img src={placemat2} />
          <img src={placemat3} />
          <img src={placemat4} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={placemat5} />
          <img src={placemat6} />
          <img src={placemat7} />
          <img src={placemat8} />
        </div>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Runner</span>
        </h3>
        <div className="product-img-flex">
          <img src={runner3} />
          <img src={runner4} />
          <img src={runner7} />
          <img src={runner8} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={runner1} />
          <img src={runner2} />
          <img src={runner5} />
          <img src={runner6} />
        </div>
      </div>
    </div>
  );
}
export default Tablelinen;
