import React from "react";
import "../css/Contact.css";

function Contact() {
  return (
    <div className="contact">
      <div className="contact-main">
        <div className="contact-flex">
          <div className="contact-left">
            <div>
              <h3>
                <span>Get</span> In Touch
              </h3>
              <div className="contact-div">
                <p>Full Name *</p>
                <input placeholder="Your Full Name"></input>
              </div>

              <div className="contact-div">
                <p>Mobile Number *</p>
                <input placeholder=" Your Mobile Number"></input>
              </div>
              <div className="contact-div">
                <p>Email *</p>

                <input placeholder="Enter Your Email"></input>
              </div>
              <div className="contact-div">
                <p>Description</p>
                <input
                  placeholder=" Messages type here"
                  className="description"
                ></input>
              </div>
              <div className="submit-btn">
                <button className="button">Submit</button>
              </div>
            </div>
          </div>
          <div className="contact-right">
            <div>
              <div className="contact-right-card">
                <h3>
                  Contact <span>Us</span>
                </h3>
                <div className="contact-address">
                  <div>
                    <h2>Email : leo@santhexports.com</h2>
                  </div>
                </div>
                <div className="contact-address">
                  <div>
                    <h2>Phone : +919843056387 </h2>
                  </div>
                </div>
                <div className="contact-address">
                  <div>
                    <h2>
                      Address : Post Box No:52, No.46, New Street, Karur - 639
                      001.
                    </h2>
                  </div>
                </div>
              </div>
              <h3 className="location-title">Location</h3>
              <div className="contact-right-card2">
                <div className="map-section">
                  <iframe
                    class="gmap_iframe"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=1600&amp;height=500&amp;hl=en&amp;q=santh exports No.46, New Street, Karur - 639 001.  Tamil Nadu, India&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
