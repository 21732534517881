import React from "react";
import image1 from "../Assets/Categories/stitching/stitching-main2.jpg";
import image2 from "../Assets/Categories/stitching/stitching1.jpg";
import image3 from "../Assets/Categories/stitching/stitching2.jpg";
import image4 from "../Assets/Categories/stitching/stitching3.jpg";
import image5 from "../Assets/Categories/stitching/stitching1.jpg";
import "../css/ProductSub.css";

function Stitching() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={image1}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Stitching</span> Section
        </h3>
        <div className="product-img-flex">
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
          <img src={image5} />
          <p>
            We have a wide working area of 25000 sq.ft of working space and are
            capable of handling bulk orders successfully with the help of 55
            imported, high-end sewing machines with a dedicated Lay - Cutting
            facility and an Online Inspection Team maintaining stringent quality
            standards. Stitching of made-ups done in domestic stitching units
            which are equipped with manual and power operated sewing Machines
            ranging 200 to 250 are used.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Stitching;
