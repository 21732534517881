import React from "react";
import "../css/About.css";
import about from "../Assets/Main-Background/about.jpg";
import image1 from "../Assets/Owner/owner1.JPG";
import image2 from "../Assets/Owner/owner2.JPG";
import image3 from "../Assets/Owner/owner3.JPG";
import image4 from "../Assets/Owner/owner4.JPG";

function About() {
  return (
    <div className="about-main">
      <div className="about-top">
        <img src={about} />
      </div>
      <div className="about-second-section">
        <div className="about-top-head">
          <h3 className="about-title">
            Quick <span>View</span>
          </h3>
          <p>
            It all started in 1997 as a small company promoted by two brothers
            Arul and Anand with few employees. Initially we started
            manufacturing home textile products to Italy and Netherland. Then
            came France, Belgium and Germany. Now we are producing and exporting
            products to most of the EU countries along with USA, Australia,
            Brazil and few Latin American countries. The initial promoters still
            own and run the company.
          </p>
        </div>
      </div>
      <div className="mission-section">
        <div className="mission-left">
          <h3 className="about-title">
            <span>Our</span> Mission
          </h3>
          <h4 className="about-sub-head">We always complete our missions</h4>
          <p>
            “SOFTLINE” Textile manufacturers and exporters of home textile are
            dedicated to helping customers in providing products and services on
            time, and to enhance the customers satisfaction by means of
            devotion, quality and value.
          </p>
          <p>
            Here at “SOFTLINE” we engage effectively, responsibly in global
            textile industry.
          </p>
          <p>
            The company aims in high standard performance in the textile market
            segments in which it competes.
          </p>
          <p>
            The company aspires to be recognized in the community it operates in
            as a safe and environmentally responsible organization.
          </p>
          <p>
            At “SOFTLINE” the company success and sustainable profit growth
            depends upon resourcing, development and retention of its talented
            human resources. We will continue to invest in building its
            organizational capacity and capability.
          </p>
        </div>
        <div className="mission-right">
          <div>
            <h3>We can deal with</h3>
            <div>
              <h4>Table Linen</h4>
            </div>
            <p>
              Our Table linen consists of, Table cover, tablemat, runner,
              breadbasket, chair pad, coaster, and napkin.
            </p>
            <div>
              <h4>Kitchen Linen</h4>
            </div>
            <p>
              Our kitchen linen consists of apron, potholder, mitten, kitchen
              towels, tea cozy, and dishcloth.
            </p>
            <div>
              <h4>Bed Linen</h4>
            </div>
            <p>
              Our Bed linen consists of, Bed cover, pillow covers, quilts, rugs
              and throws.
            </p>
            <div>
              <h4>Living Linen</h4>
            </div>
            <p>
              Our Living Linen consists of, door curtain, window curtains,
              valances, cushions
            </p>
            <div>
              <h4>Bath Linen</h4>
            </div>
            <p>
              Our Bath Linen consists of, shower curtain, bath towels, bath
              robe, bath mat.
            </p>
          </div>
        </div>
      </div>
      <h3 className="management-main-title">Management</h3>
      <div className="management">
        <div className="management-card">
          <div className="management-div">
            <img src={image1}></img>
            <h3 className="owner-name">Mr. Magdaline Joseph Babu</h3>
            <h4 className="owner-position">Partner</h4>
          </div>
        </div>
        <div className="management-card">
          <div className="management-div">
            <img src={image2}></img>
            <h3 className="owner-name">Mr. Leo Rex Sebastian </h3>
            <h4 className="owner-position">Partner</h4>
          </div>
        </div>
        <div className="management-card">
          <div className="management-div">
            <img src={image4}></img>
            <h3 className="owner-name">Mr. PRASANTH</h3>
            <h4 className="owner-position">Partner</h4>
          </div>
        </div>
        <div className="management-card">
          <div className="management-div">
            <img src={image3}></img>
            <h3 className="owner-name">Mr. PRANESH</h3>
            <h4 className="owner-position">Partner</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
