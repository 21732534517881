import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Process from "./Pages/Process";
import Products from "./Pages/Products";
import Contact from "./Pages/Contact";
import Footer from "./Layout/Footer";
// import Navbar from "./Layout/Navbar";
import BedLinen from "./Components/BedLinen";
import KitchenLinen from "./Components/KitchenLinen";
import LivingLinen from "./Components/LivingLinen";
import TableLinen from "./Components/TableLinen";
import Dyeing from "./Components/Dyeing";
import Weaving from "./Components/Weaving";
import Stitching from "./Components/Stitching";
import Checking from "./Components/Checking";
import Development from "./Components/Development";
import Nav from "./Layout/Nav";

function Main() {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/process" element={<Process />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/livinglinen" element={<LivingLinen />} />
          <Route path="/kitchenlinen" element={<KitchenLinen />} />
          <Route path="/bedlinen" element={<BedLinen />} />
          <Route path="/tablelinen" element={<TableLinen />} />
          <Route path="/development" element={<Development />} />
          <Route path="/dyeing" element={<Dyeing />} />
          <Route path="/weaving" element={<Weaving />} />
          <Route path="/stitching" element={<Stitching />} />
          <Route path="/checking" element={<Checking />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default Main;
