import React from "react";
import image1 from "../Assets/Categories/kitchen/kitchen_main.jpg";
import kitchen1 from "../Assets/Categories/kitchen/kitchen_linen1.jpg";
import kitchen2 from "../Assets/Categories/kitchen/kitchen_linen2.jpg";
import kitchen3 from "../Assets/Categories/kitchen/kitchen_linen3.jpg";
import kitchen4 from "../Assets/Categories/kitchen/kitchen_linen4.jpg";
import kitchen5 from "../Assets/Categories/kitchen/kitchen_linen5.jpg";
import kitchen6 from "../Assets/Categories/kitchen/kitchen_linen6.jpg";
import kitchen7 from "../Assets/Categories/kitchen/kitchen_linen7.jpg";
import kitchen8 from "../Assets/Categories/kitchen/kitchen_linen8.jpg";
import tea1 from "../Assets/Categories/kitchen/kitchen1.jpg";
import tea2 from "../Assets/Categories/kitchen/kitchen2.jpg";
import tea3 from "../Assets/Categories/kitchen/kitchen3.jpg";
import tea4 from "../Assets/Categories/kitchen/kitchen4.jpg";
import tea5 from "../Assets/Categories/kitchen/kitchen5.jpg";
import tea6 from "../Assets/Categories/kitchen/kitchen6.jpg";
import tea7 from "../Assets/Categories/kitchen/kitchen7.jpg";
import tea8 from "../Assets/Categories/kitchen/kitchen8.jpg";

import "../css/ProductSub.css";

function KitchenLinen() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={image1}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Kitchen</span> Linen
        </h3>
        <div className="product-img-flex">
          <img src={kitchen1} />
          <img src={kitchen2} />
          <img src={kitchen5} />
          <img src={kitchen6} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={kitchen3} />
          <img src={kitchen4} />
          <img src={kitchen7} />
          <img src={kitchen8} />
        </div>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Tea</span> Linen
        </h3>
        <div className="product-img-flex">
          <img src={tea1} />
          <img src={tea2} />
          <img src={tea5} />
          <img src={tea6} />
        </div>
      </div>
      <div className="product-sub-main">
        <div className="product-img-flex">
          <img src={tea3} />
          <img src={tea4} />
          <img src={tea7} />
          <img src={tea8} />
        </div>
      </div>
    </div>
  );
}
export default KitchenLinen;
