import React from "react";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";

import "../css/Footer.css";
function Footer() {
  return (
    <div className="container-fluid">
      <div className="Footer">
        <div className="footer-left">
          <h4>Address</h4>
          <div className="footer-right-one">
            <p>Softline Exports</p>
            <p>
              Post Box No : 52, No : 46, New Street, <br />
              Karur - 639 001.
            </p>
            <p>Tamil Nadu, India</p>
          </div>
        </div>
        <div className="footer-middle-one">
          <h4 className="footer-title">Business Hours</h4>
          <p>Monday - Friday : 10 am to 6 pm</p>
          <p>Saturday : 10 am to 2 pm</p>
          <p>Sunday : Closed</p>
        </div>

        <div className="footer-middle-two">
          <h4>Location</h4>
          <div class="gmap_canvas">
            <iframe
              class="gmap_iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=1600&amp;height=500&amp;hl=en&amp;q=santh exports No.46, New Street, Karur - 639 001.  Tamil Nadu, India&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
        <div className="footer-right">
          <h4>Social Links</h4>
          <div className="social-links">
            <AiFillFacebook className="social-icons" />
            <AiFillTwitterCircle className="social-icons" />
            <AiFillInstagram className="social-icons" />
            <AiFillLinkedin className="social-icons" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
