import React from "react";
import image1 from "../Assets/Categories/dyeing/dyeing-main.jpeg";
import dying1 from "../Assets/Categories/dyeing/dyeing1.jpg";
import dying2 from "../Assets/Categories/dyeing/dyeing2.jpg";
import dying3 from "../Assets/Categories/dyeing/dyeing3.jpg";
import dying4 from "../Assets/Categories/dyeing/dyeing4.jpg";

import "../css/ProductSub.css";

function Dyeing() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={image1}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Dyeing</span> Section
        </h3>
        <div className="product-img-flex">
          <img src={dying1} />
          <img src={dying2} />
          <img src={dying3} />
          <img src={dying4} />
          <p>
            Softline Exports had the infrastructure of dyeing capacity a minimum
            of 40 Kgs Lots to maximum 200 Kgs Lots, Both the Hank and Chess
            Dyeing is done in our own dyeing cabinet. We employ eco-friendly
            effluent treatment processes using Reverse Osmosis Water Treatment
            Plants in our dyeing units. The Total production of the units is 750
            Bundles per Day.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Dyeing;
