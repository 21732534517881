import React from "react";
import home from "../Assets/Main-Background/home-top.jpg";
import home2 from "../Assets/Main-Background/home-bottom.jpg";
import "../css/Home.css";
import { TbWorldUpload } from "react-icons/tb";
import { HiUserGroup } from "react-icons/hi";
import { GiFactory } from "react-icons/gi";
import { GiStarsStack } from "react-icons/gi";

function Home() {
  const data = [
    {
      icon: <TbWorldUpload className="test-icon" />,
      count: "15 +",
      title: "Countries Served",
    },
    {
      icon: <HiUserGroup className="test-icon" />,
      count: "500",
      title: "Employees at Factory",
    },
    {
      icon: <GiFactory className="test-icon" />,
      count: "2000",
      title: "Factory Space",
    },
    {
      icon: <GiStarsStack className="test-icon" />,
      count: "40",
      title: "Certificates",
    },
  ];
  return (
    <div className="home-main">
      <div className="home">
        <img className="home-img" src={home} />
      </div>
      <div className="home-second-section">
        <div className="home-second-left">
          <h3>
            Softline<span> Exports</span>
          </h3>
          <p>
            Softline Exports, is a Premier Manufacturing and exporting company
            for vast array of soft furnishing Products. Designing,
            manufacturing, and exporting fine textile goods across the globe.
            In-house Spinning, dyeing, weaving, Stitching till final goods
            ready. Softline Exports., is currently exporting home textile
            products worldwide to Wholesalers, Importers, Retail chain stores
            and Mail Order Catalog companies since 1993. We manufacturer of
            quality coordinating products like in Bed Linen- Cushion covers,
            Throws, Quilts, Floor cushions, Window Blinds, Curtain Panels, In
            Table Linen- Table Covers, Runners, Placemats & Napkins, In Kitchen
            Linen likes Kitchen Towels, Aprons, Pot holders & Oven mitts and
            other products, etc. In-house Spinning, dyeing, Weaving, Stitching
            till final goods ready.
          </p>
        </div>
        <div className="home-second-right">
          <h3>
            Better Way <br /> Forward
          </h3>
        </div>
      </div>
      <div className="home-third-section">
        <div className="home-third-left">
          <img className="home-img-bottom" src={home2} />
        </div>
        <div className="home-third-right">
          <h3 className="main-title">
            Softline<span className="name-tag-one"> Exports</span>
          </h3>
          <h2>
            Products<span className="name-tag"> Business</span>
          </h2>
          <div className="product-detail">
            <h3 className="sub-head">Kitchen Linen</h3>
            <p className="sub-content">
              To take a trivial example, which of us ever undertakes laborious
              physical exercise, except to obtain some advantage from it?
            </p>
          </div>
          <div>
            <h3 className="sub-head">Living Linen</h3>
            <p className="sub-content">
              Our Living Linen consists of, door curtain, window curtains,
              valances, cushions
            </p>
          </div>
          <div>
            <h3 className="sub-head">Table Linen</h3>
            <p className="sub-content">
              But who has any right to find fault with a man who chooses to
              enjoy a pleasure that has no annoying consequences, or one who
              avoids a pain that produces.
            </p>
          </div>
        </div>
      </div>
      <div className="testimonial-section">
        <div className="test-card-flex">
          {data.map((item, index) => (
            <div key={index} className="test-card">
              {item.icon}
              <h3 className="test-count">{item.count}</h3>
              <h2 className="test-head">{item.title}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Home;
