import React from "react";
import image1 from "../Assets/Categories/bed/bedlinen_main.jpg";
import image2 from "../Assets/Categories/bed/bed1.jpg";
import image3 from "../Assets/Categories/bed/bed2.jpg";
import image4 from "../Assets/Categories/bed/bed3.jpg";
import image5 from "../Assets/Categories/bed/bed4.jpg";
import "../css/ProductSub.css";

function BedLinen() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={image1}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Bed</span> Linen
        </h3>
        <div className="product-img-flex">
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
          <img src={image5} />
        </div>
      </div>
    </div>
  );
}
export default BedLinen;
