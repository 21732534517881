import React from "react";
import image1 from "../Assets/Categories/developments/development-main.jpg";
import tote1 from "../Assets/Categories/developments/tote1.jpg";
import tote2 from "../Assets/Categories/developments/tote2.jpg";
import tote3 from "../Assets/Categories/developments/tote3.jpg";
import tote4 from "../Assets/Categories/developments/tote4.jpg";
import beach1 from "../Assets/Categories/developments/beach1.jpg";
import beach2 from "../Assets/Categories/developments/beach2.jpg";
import beach3 from "../Assets/Categories/developments/beach3.jpg";
import beach4 from "../Assets/Categories/developments/beach4.jpg";

import "../css/ProductSub.css";

function Development() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={image1}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Tote</span> Bag
        </h3>
        <div className="product-img-flex">
          <img src={tote1} />
          <img src={tote2} />
          <img src={tote3} />
          <img src={tote4} />
        </div>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Beach</span> Mattress
        </h3>
        <div className="product-img-flex">
          <img src={beach1} />
          <img src={beach2} />
          <img src={beach3} />
          <img src={beach4} />
        </div>
      </div>
    </div>
  );
}
export default Development;
