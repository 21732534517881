import React from "react";
import image1 from "../Assets/Categories/weaving/weaving-main2.jpg";
import image2 from "../Assets/Categories/weaving/weaving1.jpg";
import image3 from "../Assets/Categories/weaving/weaving2.jpg";
import image4 from "../Assets/Categories/weaving/weaving3.jpg";
import image5 from "../Assets/Categories/weaving/weaving4.jpg";
import "../css/ProductSub.css";

function Weaving() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={image1}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Weaving</span> Section
        </h3>
        <div className="product-img-flex">
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
          <img src={image5} />
          <p>
            Our Weaving Unit comprises of 8 high-speed automatic wider-width
            looms of 135-inch with a production capacity of 48,000 meters per
            month with in-house warping and cone winding facility. We also have
            contracts with more than 500 handlooms, power looms, and jacquard
            looms which adds to our strength. Our quality of weaving machinery
            are Rutici, Rapiers and Air Jet Looms were used. Our one month
            production capacity is around 2,00,000 meters.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Weaving;
