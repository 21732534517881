import React from "react";
import "../css/Products.css";
import product from "../Assets/Main-Background/product.jpg";
import product1 from "../Assets/products/product1.jpg";
import product2 from "../Assets/products/product2.jpg";
import product3 from "../Assets/products/product3.jpg";
import product4 from "../Assets/products/product4.jpg";
import product5 from "../Assets/products/product5.jpg";
import product6 from "../Assets/products/product6.jpg";

function Products() {
  return (
    <div className="product">
      <div className="product-top">
        <img src={product} />
      </div>
      <div className="product-content">
        <p>
          A room should feel collected, and not decorated. Atlas home textiles
          products are always a class apart with high quality and exclusive
          designs. Our design Studio's mojo is "Expand your imagination -
          Express your creativity". Lead by our eminent designer from Holland
          and our inhouse designers for innovative designs that your home
          deserves. With the best quality of fabric material chosen, your home
          will feel the pleasant living.
        </p>
      </div>
      <div className="product-img-flex">
        <div className="product-card-one">
          <img src={product1} />
          <div className="product-caption">
            <h3>Bed</h3>
          </div>
        </div>
        <div className="product-card-one">
          <img src={product2} />
          <div className="product-caption">
            <h3>Cushion</h3>
          </div>
        </div>
        <div className="product-card-one">
          <img src={product3} />
          <div className="product-caption">
            <h3>Table Linen</h3>
          </div>
        </div>
      </div>
      <div className="product-img-flex">
        <div className="product-card-one">
          <img src={product4} />
          <div className="product-caption">
            <h3>Throws</h3>
          </div>
        </div>
        <div className="product-card-one">
          <img src={product5} />
          <div className="product-caption">
            <h3>Kitchen Linen</h3>
          </div>
        </div>
        <div className="product-card-one">
          <img src={product6} />
          <div className="product-caption">
            <h3>Curtain</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Products;
