import React from "react";
import image1 from "../Assets/Categories/checking/checking-main.jpg";
import image2 from "../Assets/Categories/checking/checking1.jpg";
import image3 from "../Assets/Categories/checking/checking2.jpg";
import image4 from "../Assets/Categories/checking/checking3.jpg";
import image5 from "../Assets/Categories/checking/checking4.jpg";
import "../css/ProductSub.css";

function Checking() {
  return (
    <div className="bedlinen">
      <div className="product-sub-top">
        <img src={image1}></img>
      </div>
      <div className="product-sub-main">
        <h3>
          <span>Checking & Packing</span> Section
        </h3>
        <div className="product-img-flex">
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
          <img src={image5} />
          <p>
            Checking of the Fabrics and Made ups done in the manufacturing site
            by skilled checkers and quality control chart in maintained to
            control defects in further productions. Quality fabrics are
            delivered to stitching time bound manner in our uniqueness. Each and
            every stage of production is monitored and scrutinized well in time
            for effective high quality finished product. As per the
            international requirement each and every carton is packed and filled
            with the mix and match of the specified products as per their client
            requirements.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Checking;
